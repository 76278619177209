import { api } from 'JIX/utils.js';
import SvgIcon from 'JIX/svg_icon.js';
import { __ } from 'JIX/gettext.js';

import 'icons/geolocation.svg';

const API_VERSION = 1;

// Current api version url.
function api_url(f) {
    return "/api/geo/v" + API_VERSION + "/" + f;
}

// Creates a badge template.
function template(entry, selection) {
    if (entry.loading) {
        return entry.text;
    }

    const name = (selection ? entry.canonical_name : entry.text_hl) || entry.text;
    const span = document.createElement('span');
    if (selection) {
        span.innerText = ' ' + name + ' ';
    } else {
        const span2 = document.createElement('span');
        span2.innerHTML = name;
        span.append(' ', span2);
    }

    const isRadius = entry.id < 0;
    if (isRadius) {
        const geoicon = new SvgIcon('geolocation');
        span.prepend(geoicon.getElement());
    }

    if (!selection) {
        const badge_class = 'badge-geoarea-typeid-' + (isRadius ? 'radius' : entry.typeid);
        const extra_class = selection ? 'area-selection' : 'area-result';
        const label = document.createElement('span');
        label.classList.add(extra_class, 'badge', badge_class);
        label.innerText = isRadius ? __("Radius") : entry.type;
        span.prepend(label);
    }

    return span;
}

// Returns a select2 config for setting up geoarea select2 fields.
export default function getGeoAreaConfig(userOptions = {}) {
    const lang = document.documentElement.lang;
    let context;
    let radius = false;
    let no_country = false;
    let type_filter;
    let orig_default_list = [];
    let id_radius = -100;

    if (userOptions) {
        if (userOptions['context'] !== undefined) {
            context = userOptions['context'];
            delete userOptions['context'];
        }
        if (userOptions['radius'] !== undefined) {
            radius = userOptions['radius'];
            delete userOptions['radius'];
        }
        if (userOptions['no_country'] !== undefined) {
            no_country = userOptions['no_country'];
            delete userOptions['no_country'];
        }
        if (userOptions['type_filter'] !== undefined) {
            type_filter = userOptions['type_filter'];
            delete userOptions['type_filter'];
        }
        if (userOptions['id_radius'] !== undefined) {
            id_radius = userOptions.id_radius;
            delete userOptions['id_radius'];
        }
        if (userOptions['default_list'] !== undefined) {
            orig_default_list = userOptions.default_list.slice();
        }
    }

    function update_default_list() {
        const new_default_list = orig_default_list.slice();
        if (radius) {
            const g_elem = {
                id: id_radius,
                text: __('Nær placering'),
            };
            new_default_list.unshift(g_elem);
        }
        userOptions.default_list = new_default_list;
    }
    update_default_list();

    // Store latest query. processResults may run out of order, so if you
    // quickly delete all characters, a pending request may override the
    // now empty query.
    let q;
    const select2_options = {
        multiple: true,
        minimumInputLength: 0,
        ajax: {
            transport: async (params, success, failure) => {
                if (params.data.q.trim().length === 0) {
                    // processResults will handle an empty query as a special case, so we
                    // don't need to make an API call
                    const res = { results: [] };
                    success(res);
                } else {
                    const res = await api('GET', api_url('suggest-name'), params.data);
                    if (res.success) {
                        success(res);
                    } else { failure(); }
                }
            },
            delay: 250,
            data: (params) => {
                q = params.term ? params.term.trim().toLowerCase() : "";
                return { q: q, context: context, lang: lang, no_country: no_country ? 1 : 0, type_filter: type_filter };
            },
            cache: true,
            processResults: (data) => {
                if (q.length === 0) {
                    data.results = userOptions.default_list;
                }
                return data;
            }
        },
        width: "100%",
        dropdownCssClass: "bigdrop",
        containerCssClass: "form-control",
        templateResult:    (entry) => template(entry, 0),
        templateSelection: (entry) => template(entry, 1),
        ...userOptions, // UserOptions override the default options and are therefore merged last
    };

    return select2_options;
}
