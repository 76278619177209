import $ from 'jquery';
import getGeoAreaConfig from 'JIX/select2/geoarea.js';
const stashes = window.Stash['widgets/jix_geoarea'];
const ID_RADIUS = -100;

function init(id, stash) {
    const element = document.getElementById(id);
    const select2_options = getGeoAreaConfig({
        ...stash.options,
        radius: stash.radius,
        id_radius: ID_RADIUS,
    });
    const $select2 = $(element).jix_select2(select2_options);

    if (stash.radius) {
        $select2.on('change', () => {
            const has_geo = stash.radius && $select2.val().indexOf(ID_RADIUS.toString()) >= 0;
            const event = new CustomEvent('radius_change.jix', { detail: { radius_selected: has_geo } });
            $select2.get(0).dispatchEvent(event);
        });
    }
    $select2.val(stash.init_vals).trigger("change");
}

stashes.forEach(({ id, stash }) => init(id, stash));
