import { createApp } from 'vue';
import { ref } from 'vue';

import SvgIcon from 'JIX/components/SvgIcon.vue';

export default class {
    constructor(name, size) {
        this._name = ref(name);
        this._app = createApp(SvgIcon, { name: this._name.value, size });
        const el = document.createElement('div');
        this._vm = this._app.mount(el);
    }

    setName(newName) {
        this._name.value = newName;
    }

    getVm() {
        return this._vm;
    }

    getElement() {
        return this._vm.$el;
    }
}
